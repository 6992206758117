import React from "react";
import styled from "styled-components";

function Linen() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-3 items-center">
        <div className="col-span-1">
          <H1 className="text-3xl font-semibold mb-5"> Hotel Linen Bedding</H1>
          <ul className="list-disc">
            <Li>BATH TOWELS TOWELS</Li>
            <Li>BED-RUNNER</Li>
            <Li>BED LiNEN</Li>
            <Li>SPA LiNEN</Li>
            <Li>BATH LiNEN</Li>
            <Li>BED SHEETS</Li>
            <Li>DUVETS MATTRESS</Li>
            <Li>PROTECTORS PILLOW</Li>
            <Li>FEATHER BEDS</Li>
            <Li>BED RUNNERS</Li>
          </ul>
        </div>
        <figure className="w-full col-span-2">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/linen.png`}
            alt=""
            className="w-full"
          />
        </figure>
      </div>
    </div>
  );
}

export default Linen;

  const H1 = styled.h1`
    color: ${(props) => props.theme.colors.green};
  `;

const Li = styled.li`
  color: ${(props) => props.theme.colors.green};
`;
