import styled from "styled-components";

function HotelRoom() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div>
        <h1 className="mb-5">Hotel Room supplies </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/hotelRoom/1.png`}
            alt=""
            className="w-full"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/hotelRoom/2.png`}
            alt=""
            className="w-full"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/hotelRoom/3.png`}
            alt=""
            className="w-full"
          />
        </div>
      </div>
      {/* room supplies */}
      <div className="grid grid-cols-1 md:grid-cols-4 mt-5 items-center">
        <div className="col-span-1">
          <H1 className="text-3xl font-semibold mb-5"> Hotel Linen Bedding</H1>
          <ul className="list-disc">
            <Li>ROOM AMENITIES</Li>
            <Li>GUEST ROOM APPLIANCES</Li>
          </ul>
        </div>
        <figure className="col-span-3 w-ful">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/hotelRoom/4.png`}
            alt=""
            className="w-full h-80 "
          />
        </figure>
      </div>
      {/*  */}
      <figure className="w-ful">
        <img
          src={`${process.env.PUBLIC_URL}/images/Product/hotelRoom/5.png`}
          alt=""
          className="w-full mt-5"
        />
      </figure>
    </div>
  );
}

export default HotelRoom;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.green};
`;

const Li = styled.li`
  color: ${(props) => props.theme.colors.green};
`;
