import styled from "styled-components";

function About() {
  return (
    <Div className="mt-12">
      <div className="container mx-auto px-4 md:px-12">
        <div className="grid grid-cols-3 items-center">
          <Box className="py-36 col-span-3 lg:col-span-1">
            <Content>
              <h1 className="ml-4 text-4xl">About us :</h1>
              <p className="mt-12 text-2xl ml-4 leading-9">
                We are a leading provider of hotel supplies, offering a wide
                range of products to meet the needs of the hospitality <br />
                industry. Since our inception, we have been dedicated to
                delivering exceptional value to our customers through high
                quality products, competitive pricing, and outstanding service.
              </p>
            </Content>
          </Box>
          {/*  */}
          <div className="col-span-3 lg:col-span-2">
            <H1 className="text-5xl text-center leading-[70px]">
              Crafting Luxury, Delivering Quality Ensuring Guest Comfort
            </H1>
          </div>
        </div>
      </div>
    </Div>
  );
}

export default About;

const Div = styled.div`
  position: relative;
  background-image: url("images/about.png");
  background-position: center;
  background-size: cover;
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.colors.white};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.green};
    mix-blend-mode: multiply;
    z-index: 1;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1; /* Ensure the content is above the pseudo-element */
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.white};
`;
