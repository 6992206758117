// start in component
import About from "../component/About";
import Featured from "../component/Featured";
import Header from "../component/Header";
import Services from "../component/Services";

function Home() {
  return (
    <div>
      <Header
        img={`${process.env.PUBLIC_URL}/images/hero.jpg`}
        text="Discover Luxury and Comfort"
        textTow="with Our Hotel Linens"
        btn="shop now"
      />
      <Featured />
      <About />
      <Services />
    </div>
  );
}

export default Home;
