import React from "react";

function Lighting() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <img
          src={`${process.env.PUBLIC_URL}/images/Product/Lighting/1.png`}
          alt=""
          className="w-full h-72"
        />

        <img
          src={`${process.env.PUBLIC_URL}/images/Product/Lighting/2.png`}
          alt=""
          className="w-full h-72"
        />
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/Product/Lighting/3.png`}
        alt=""
        className="w-full mt-5"
      />
    </div>
  );
}

export default Lighting;
