import styled from "styled-components";

function Lists() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <ul className="list-disc">
          <Li>BATHROOM BINS-TISSUE BOX</Li>
          <Li>SOAP DISPENSER</Li>
          <Li>TOWEL RACKS OR BARS</Li>
          <Li>TOILET PAPER HOLDERS</Li>
          <Li>SHOWER CADDIES</Li>
          <Li>TOOTHBRUSH HOLDERS</Li>
          <Li>BATHROOM MIRRORS</Li>
        </ul>

        {/*  */}
        <ul className="list-disc">
          <Li>BATH SHEETS</Li>
          <Li>BATH TOWELS</Li>
          <Li>AIR FRESHENERS</Li>
          <Li>TOILET BRUSH AND HOLDER</Li>
          <Li>AIR FRESHENERS</Li>
          <Li>TISSUE BOX COVERS</Li>
          <Li>SOAP DISHES</Li>
        </ul>

        {/*  */}
        <ul className="list-disc">
          <Li>LIGHT FIXTURES</Li>
          <Li>HAND TOWELS</Li>
          <Li>FACE TOWELS</Li>
          <Li>BATH MATS</Li>
          <Li>BATH RUGS</Li>
          <Li>BIDET TOWEL</Li>
          <Li>BATH ROBS & KIMONOS</Li>
        </ul>
      </div>
    </div>
  );
}

export default Lists;

const Li = styled.li`
  color: ${(props) => props.theme.colors.green};
`;
