// main color in all websites
const theme = {
  colors: {
    green: "#5B7352",
    white: "#fff",
    lightWhite: "#E1E1E1",
    black: "#000",
    yellow: "#ffd700",
    grayBack: "#F5F5F5",
  },
};
export default theme;
