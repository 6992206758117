// start in react router dom
import { BrowserRouter, Route, Routes } from "react-router-dom";
// start in theme
import { ThemeProvider } from "styled-components";
import theme from "./theme";
// start in component
import Navbar from "./component/Navbar";
import Home from "./pages/Home";
import Product from "./pages/Product";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<Navbar />}>
              <Route path="/" element={<Home />} />
              <Route path="product" element={<Product />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
