import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <FooterAll className="mt-12">
      <div className="container mx-auto px-4 md:px-12">
        <div className="grid grid-cols-1 md:grid-cols-9 gap-3">
          <div className="flex flex-col col-span-3">
            <figure className="w-[134px] h-24">
              <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="" />
            </figure>
            <p className="text-[15px] text-white">
              A company specializing in high-quality linens and hotel supplies,
              catering to the needs of hotels and resorts.
            </p>
          </div>
          {/*  */}
          <ul className="text-white col-span-2">
            <li className="my-5">
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/product">Product</NavLink>
            </li>
          </ul>
          {/*  */}
          <div className="mt-5 col-span-2">
            <Title>services</Title>
            <ul>
              <Li>Primary Services</Li>
              <Li>Hotel Room Services</Li>
              <Li>Bathroom Services</Li>
              <Li>Bed and Pillow Protection</Li>
            </ul>
          </div>
          {/*  */}
          <div className="mt-5 col-span-2">
            <Title>Conect us</Title>
            <ul>
              <Li>Tel : + 974 3997 4447</Li>
              <Li>Email : info@linen-bedding.com</Li>
              <Li>Address : Doha-Qatar</Li>
            </ul>
          </div>
        </div>
      </div>
    </FooterAll>
  );
}

export default Footer;

const FooterAll = styled.footer`
  background-color: ${(props) => props.theme.colors.green};
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  position: relative;
  color: ${(props) => props.theme.colors.white};

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 91px;
    background-color: #ac8864;
    height: 2px;
  }
`;

const Li = styled.li`
  margin: 10px 0;
  color: ${(props) => props.theme.colors.white};
`;
