import Bathroom from "../component/Bathroom";
import Chef from "../component/Chef";
import Header from "../component/Header";
import HotelRoom from "../component/HotelRoom";
import Lighting from "../component/Lighting";
import Linen from "../component/Linen";
import Lists from "../component/Lists";
import ListsImg from "../component/ListsImg";
import ThreeImg from "../component/ThreeImg";

function Product() {
  return (
    <div>
      <Header
        img={`${process.env.PUBLIC_URL}/images/Product/product.png`}
        text="Discover Luxury in Every Detail"
      />
      <Linen />
      <ThreeImg />
      <HotelRoom />
      <Bathroom />
      <Lists />
      <ListsImg />
      <Lighting />
      <Chef />
    </div>
  );
}

export default Product;
