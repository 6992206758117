import React, { useState } from "react";
import styled from "styled-components";

function Services() {
  const [selectedService, setSelectedService] = useState(null);

  const servicesContent = {
    "Primary Services": {
      title: "Custom Tailoring",
      description:
        "Custom linens designed to meet your specific needs for comfort and luxury.",
      titleTow: "Fast Delivery",
      descriptionTow:
        "Fast and reliable delivery services to ensure timely receipt of orders.",
      titleThree: "Technical Support",
      descriptionThree:
        "Continuous technical support to resolve any inquiries or issues.",
    },
    "Hotel Room Services": {
      title: " Bed Side Mats",
      description: "Side mats that add comfort and elegance to rooms.",
      titleTow: "Bed Linen",
      descriptionTow: "oft and luxurious bed linens for a comfortable sleep.",
      titleThree: " Bed Runners",
      descriptionThree: "Bed runners that enhance room elegance.",
    },
    "Bathroom Services": {
      title: "Bath Sheets and Towels",
      description: "Luxurious bath sheets and towels for guest comfort.",
      titleTow: " Bath Mats and Rugs",
      descriptionTow: "Bath mats and rugs for added safety and comfort",
      titleThree: " Bath Robes & Kimonos",
      descriptionThree:
        "Soft bath robes and kimonos for a spa-like experience.",
    },
    "Bed and Pillow Protection": {
      title: "Mattress Protectors 1",
      description: "Mattress protectors for clean and safe sleeping.  ",
      titleTow: " Pillow Protectors 2",
      descriptionTow: "Pillow protectors to maintain quality and cleanliness",
      titleThree: " Feather Beds 3",
      descriptionThree:
        "Luxurious feather beds for a unique sleeping experience.",
    },
  };

  return (
    <Div className="mt-12">
      <div className="grid grid-cols-1 md:grid-cols-3">
        <Content className="py-20 px-12 col-span-1">
          <H1 className=" text-4xl capitalize font-semibold text-center">
            our services :
          </H1>
          <ul className="mt-8">
            {Object.keys(servicesContent).map((service) => (
              <Li key={service} onClick={() => setSelectedService(service)}>
                {service}
              </Li>
            ))}
          </ul>
        </Content>
        <div className="py-20 px-12 mt-20 col-span-2">
          {selectedService ? (
            <div>
              <div>
                <h1 className="text-2xl font-bold text-white">
                  {servicesContent[selectedService].title}
                </h1>
                <p className="mt-2 text-lg text-white">
                  {servicesContent[selectedService].description}
                </p>
              </div>

              <div className="mt-5">
                <h1 className="text-2xl font-bold text-white">
                  {servicesContent[selectedService].titleTow}
                </h1>
                <p className="mt-2 text-lg text-white">
                  {servicesContent[selectedService].descriptionTow}
                </p>
              </div>

              <div className="mt-5">
                <h1 className="text-2xl font-bold text-white">
                  {servicesContent[selectedService].titleThree}
                </h1>
                <p className="mt-2 text-lg text-white">
                  {servicesContent[selectedService].descriptionThree}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl font-bold text-white">
                Select a service to view details
              </h1>
            </div>
          )}
        </div>
      </div>
    </Div>
  );
}

export default Services;

const Div = styled.div`
  position: relative;
  background-image: url("images/services.png");
  background-position: center;
  background-size: cover;
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.green};
  mix-blend-mode: multiply;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.green};
    z-index: -1;
  }
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.white};
`;

const Li = styled.li`
  margin: 40px 0;
  font-size: 25px;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.lightGreen};
  }
`;
