import React from "react";

function ListsImg() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <figure>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/listsImg/1.png`}
            alt=""
            className="w-full h-full"
          />
        </figure>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/listsImg/2.png`}
            alt=""
            className="w-full h-72"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/listsImg/3.png`}
            alt=""
            className="w-full h-72"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/listsImg/4.png`}
            alt=""
            className="w-full h-72"
          />
        </div>
      </div>
      {/*  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
        <img
          src={`${process.env.PUBLIC_URL}/images/Product/listsImg/5.png`}
          alt=""
          className="w-full h-72 col-span-1"
        />

        <img
          src={`${process.env.PUBLIC_URL}/images/Product/listsImg/6.png`}
          alt=""
          className="w-full h-72 col-span-2"
        />
      </div>
    </div>
  );
}

export default ListsImg;
