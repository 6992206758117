import React from "react";

function ThreeImg() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/Four/1.png`}
            alt=""
            className="w-full h-full md:h-[98vh]"
          />
        </div>
        <div className=" flex flex-col gap-4">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/Four/2.png`}
            alt=""
            className="rounded-2xl h-full md:h-[48vh]"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/Four/3.png`}
            alt=""
            className="h-full md:h-[48vh]"
          />
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/Product/Four/4.png`}
        alt=""
        className="w-full h-full mt-5"
      />
    </div>
  );
}

export default ThreeImg;
