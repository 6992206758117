import React from "react";

function Chef() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <h1>Porcelain-Buffet ware-Chef cook’s clothes</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <figure>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/1.png`}
            alt=""
            className="w-full h-screen"
          />
        </figure>

        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/2.png`}
            alt=""
            className="w-full h-[50vh]"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/3.png`}
            alt=""
            className="w-full h-[50vh]"
          />
        </div>
      </div>
      {/*  */}
      <img
        src={`${process.env.PUBLIC_URL}/images/Product/chef/4.png`}
        alt=""
        className="w-full mt-5"
      />
      {/*  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="col-span-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/5.png`}
            alt=""
            className="w-full mt-5 h-[32vh]"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/6.png`}
            alt=""
            className="w-full mt-5 h-[32vh]"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/7.png`}
            alt=""
            className="w-full mt-5 h-[32vh]"
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/Product/chef/8.png`}
          alt=""
          className="w-full h-screen mt-5 col-span-2"
        />
      </div>
      {/*  */}
      <div className="grid grid-cols-1 md:grid-cols-3 mt-5">
        <div className="col-span-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/9.png`}
            alt=""
            className="w-full h-64"
          />
        </div>
        <div className="col-span-2">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/10.png`}
            alt=""
            className="w-full h-64 rounded-xl"
          />
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/Product/chef/11.png`}
        alt=""
        className="w-full mt-5 h-[400px]"
      />
      {/*  */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
        <div className="">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/12.png`}
            alt=""
            className="w-full h-screen"
          />
        </div>
        <div className="">
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/13.png`}
            alt=""
            className="w-full h-[50vh] "
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/chef/14.png`}
            alt=""
            className="w-full h-[50vh] "
          />
        </div>
      </div>
    </div>
  );
}

export default Chef;
