import styled from "styled-components";

function Featured() {
  return (
    <div className="container mx-auto px-4 md:px-12">
      <div className="mt-12">
        <H1 className="text-5xl font-semibold">Featured Products</H1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-1.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Hotel Room supplies </Title>
          </Wrapper>

          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-2.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Lighting</Title>
          </Wrapper>

          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-3.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Bathroom Accessories</Title>
          </Wrapper>

          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-4.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Hotel Linen Bedding</Title>
          </Wrapper>

          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-5.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Porcelain-Buffet ware</Title>
          </Wrapper>

          <Wrapper>
            <Img
              src={`${process.env.PUBLIC_URL}/images/featured/featured-6.png`}
              alt=""
              className="w-full rounded-lg"
            />
            <Title>Hotel Amenities</Title>
          </Wrapper>
        </div>
      </div>
    </div>
  );
}

export default Featured;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.green};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden; /* To hide any overflow from scaling the image */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    background-color: ${(props) => props.theme.colors.green};
    transition: opacity 0.3s;
  }

  &:hover::before {
    opacity: 0.4;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover h1 {
    opacity: 1;
  }
`;

const Img = styled.img`
  display: block;
  border-radius: 0.5rem; /* This is equivalent to Tailwind's "rounded-lg" */
  z-index: -1;
  transition: transform 0.3s; /* To smoothly transition the scaling effect */
  transform: scale(1);
`;

const Title = styled.h1`
  position: absolute;
  left: 10px;
  bottom: 5px;
  color: ${(props) => props.theme.colors.white};
  font-size: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 5;
`;
