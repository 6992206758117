import styled from "styled-components";

function Bathroom() {
  return (
    <div className="container mx-auto px-4 md:px-12 mt-12">
      <div>
        <H1 className="text-3xl font-semibold mb-5"> Bathroom Accessories</H1>
        <img
          src={`${process.env.PUBLIC_URL}/images/Product/bathroom/1.png`}
          alt=""
          className="w-full"
        />
      </div>
      {/*  */}
      <div className="grid grid-cols-1  md:grid-cols-2 gap-8 mt-5">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/bathroom/2.png`}
            alt=""
            className="w-full"
          />
        </div>

        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/Product/bathroom/3.png`}
            alt=""
            className="mb-4 w-full"
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/Product/bathroom/4.png`}
            alt=""
            className=" w-full"
          />
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Bathroom;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.green};
`;
