import styled from "styled-components";

function Header({ img, text, textTow, btn }) {
  return (
    <Section
      img={img}
      className="w-full h-screen flex flex-col justify-center items-center"
    >
      <H1 className="text-white text-5xl font-bold uppercase  text-center">
        {text}
      </H1>
      <H1 className="text-white text-5xl font-bold uppercase  text-center mt-14">
        {textTow}
      </H1>
      {btn && (
        <Button className="mt-12 rounded-xl px-2 py-2 text-[35px] font-semibold">
          {btn}
        </Button>
      )}
    </Section>
  );
}

export default Header;

const Section = styled.section`
  position: relative;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(52, 52, 52, 0.4) 9.15%,
      rgba(0, 0, 0, 0.5) 18.3%
    );

    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 2;
  }
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.white};
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.green};
`;
