import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Footer";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Nav className="border-gray-200 h-16">
      <div className="container mx-auto px-12 flex flex-wrap items-center justify-between">
        <NavLink
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/Logo.png`}
            className="w-[90px] h-16"
            alt="Flowbite Logo"
          />
        </NavLink>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen}
          onClick={handleToggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            <li className="text-2xl capitalize text-black md:text-white">
              <NavLink to="/" onClick={handleToggleMenu}>
                Home
              </NavLink>
            </li>
            <li className="text-2xl capitalize text-black md:text-white">
              <NavLink to="/product" onClick={handleToggleMenu}>
                Product
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
      <Footer />
    </Nav>
  );
}

export default Navbar;

const Nav = styled.nav`
  background-color: ${(props) => props.theme.colors.green};
`;
